@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* General styles */
body {
  font-family: 'Inter', sans-serif;
  background-color: #000;
  color: #E0E0E0;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;

}

p {
  font-size: 17px;
  color: #B4BCD0
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  max-width: 600px;
  background: linear-gradient(45deg, rgba(105, 0, 131, 0.3), rgba(105, 0, 131, 0.2));
  box-shadow: rgba(179, 0, 224, 0.10);
  border-radius: 4px;
  border: 1px solid #5C236A;
  padding: 12px 24px;
  margin: 20px 0px;
}

.contact-form label {
  margin-top:24px;
}

.contact-form form {
  display:flex;
  flex-direction:column;
  text-align: left;
}

.contact-form input[type="text"] {
  width:100%;
}

.contact-form .primary-button {
  margin: 24px 0px;
}

.thankyou {
  padding-bottom:24px;
}

.tier-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
}

.tier {
  background: linear-gradient(45deg, rgba(105, 0, 131, 0.3), rgba(105, 0, 131, 0.2));
  box-shadow: rgba(179, 0, 224, 0.10);
  border-radius: 4px;
  border: 1px solid #5C236A;
  padding: 8px;
  margin: 20px 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  max-width: 30%;
  margin: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
}

.tier:hover {
  box-shadow: rgba(179, 0, 224, 0.50);
  transition: box-shadow 0.3s ease-in-out;
}

.tierheader {
  color: white;
}

.tier h3 {
  margin: 12px;
}

.container {
  max-width: 800px;
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 56px;
  margin-bottom: 0px;
  color: #F7F8F8;
  line-height: 80px;
  max-width: 800px;
}

h2 {
  font-size: 42px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: center;
  color: #F7F8F8;
}

.subhead {
  color: #B4BCD0;
  max-width: 600px;
  margin-bottom: 56px;
}

h3 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: center;
  color: #B4BCD0;
}

p {
  font-size: 17px;
}

.benefits {
  list-style: none;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tier hr {
  border: .5px solid #5C236A;
  margin: 20px 0px;
}

.benefits li {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: top;
  text-align: left;
  line-height: 160%;
  margin: 4px 0px;
}

#check-icon {
  color: #B300E0;
  padding: 4px 8px;
  ;
}

label {
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid #5C236A;
  border-radius: 4px;
  background-color: transparent;
  color: #E0E0E0;
  font-size: 17px;
  margin-top: 0px;
  width: 80%;

}

.page-header {
  margin-top:48px;
}

.inputHeader {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
  gap: 20px;
  text-align: left;
}

div.input-card {
  padding: 8px;
}

.container div h3 {
  text-align: center;
}

.projectHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top:48px;
}

.outputHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.outputcontrols {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  gap: 20px;
}

.outputHeader>button[id="record"] {
  margin: 4px 0px;
}

.dropdown {
  border-radius: 4px;
  background: transparent;
  border: 1px solid #B300E0;
  color: white;
  padding: 12px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
  width: 258px;
  margin: 0px auto;
}

.dropdown #expand {
  margin-left: 4px;
}

.dropdown-list {
  position: absolute;
  z-index: 99;
  background: black;
  box-shadow: rgba(179, 0, 224, 0.10);
  border-radius: 4px;
  border: 1px solid #5C236A;
  padding: 12px 12px;
  margin: 20px 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  margin-top: 0px;
}

.dropdown-list li {
  list-style: none;
  margin: 12px Auto;
}

.dropdown-list li:hover {
  color: #DB5BFB;
}

.dropdown-item-tag {
  padding: 4px 4px;
  margin-left: 8px;
  border: 1px solid #DB5BFB;
  border-radius: 4px;
  color:white;
}

.dropdown-item-tag:hover {
  border: 1px solid white; 
  color:white;
}

button[id="record"] {
  border-radius: 4px;
  background: linear-gradient(217deg, #B300E0 0%, #DB5BFB 100%);
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
  max-width: 400px;
  margin: 4px auto;
  /* Center the buttons horizontally */
}

button[type="submit"],
button[id="stop"],
button[id="logout"],
button[id="login"] {
  border-radius: 200px;
  background: linear-gradient(217deg, #B300E0 0%, #DB5BFB 100%);
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
  max-width: 400px;
  margin: 4px auto;
  /* Center the buttons horizontally */
}

.primary-button {
  border-radius: 200px;
  background: linear-gradient(217deg, #B300E0 0%, #DB5BFB 100%);
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  cursor: pointer;
  max-width: 400px;
  margin: 4px auto;
  /* Center the buttons horizontally */
}

button[id="stop"] {
  background-color: #F56565;
}

button[id="back"] {
  background-color: #3d7de0;
  text-align: center;
  border: 1px solid #3d7de0;
  color: white;
  padding: 6px 12px;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 400px;
  z-index: 10;
  position: absolute;
  /* Change position to absolute */
  top: 12px;
  /* Position at the top */
  left: 12px;
  /* Position at the left */
  z-index: 1;
  /* Make sure it's above other content */
}

button[id="expand"] {
  background-color: transparent;
  text-align: center;
  border: 1px solid #5C236A;
  color: #444;
  padding: 12px 18px;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 400px;
  z-index: 10;
  position: absolute;
  /* Change position to absolute */
  top: 14px;
  /* Position at the top */
  right: 14px;
  /* Position at the left */
  z-index: 1;
  /* Make sure it's above other content */
  transition: border, color 0.1s ease-in-out;
}

button[id="expand"]:hover {
  border: 1px solid #007bff;
  color: #007bff;
}

button[id="edit"] {
  background-color: transparent;
  text-align: center;
  border: 1px solid #5C236A;
  color: #444;
  padding: 12px 18px;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 400px;
  z-index: 10;
  position: absolute;
  /* Change position to absolute */
  top: 14px;
  /* Position at the top */
  right: 14px;
  /* Position at the left */
  z-index: 1;
  /* Make sure it's above other content */
  transition: border, color 0.1s ease-in-out;
}

button[id="edit"]:hover {
  border: 1px solid #007bff;
  color: #007bff;
}

button[id="save"] {
  background-color: transparent;
  text-align: center;
  border: 1px solid #5C236A;
  color: #5C236A;
  padding: 12px 18px;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 400px;
  z-index: 10;
  position: absolute;
  /* Change position to absolute */
  top: 20px;
  /* Position at the top */
  right: 20px;
  /* Position at the left */
  z-index: 1;
  /* Make sure it's above other content */
  transition: border, color 0.1s ease-in-out;
}

button[id="save"]:hover {
  border: 1px solid #007bff;
  color: #007bff;
}

button[id="delete"] {
  background-color: transparent;
  text-align: center;
  border: 1px solid #5C236A;
  color: #444;
  padding: 12px 18px;
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 400px;
  z-index: 10;
  position: absolute;
  /* Change position to absolute */
  top: 14px;
  /* Position at the top */
  left: 14px;
  /* Position at the left */
  z-index: 1;
  /* Make sure it's above other content */
  transition: border, color 0.1s ease-in-out;
}

button[id="delete"]:hover {
  border: 1px solid #b43d3d;
  color: #b43d3d;
}

/* ------------Toggle------------- */

.toggle-container {
  /*display: flex;*/
  display: none;
  align-items: center;
  justify-content: space-between;
  border-radius: 200px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border-radius: 200px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 200px;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 200px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 200px;
}

input:checked+.toggle-slider {
  background-color: #2196F3;

}

input:checked+.toggle-slider:before {
  transform: translateX(26px);
}

.toggle-slider:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  margin: 3px;
  background: #fff;
  position: absolute;
  bottom: 0;
  transition: all 0.3s;
  border-radius: 200px;
}

input:checked+.toggle-slider:after {
  transform: translateX(26px);
}

.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:after {
  border-radius: 50%;
}

.toggle-label {
  margin: 0 10px;
}


.icon {
  height: 20px;
  width: 20px;
}

.delete-icon {
  color: #5C236A;
}

.expand-icon {
  color: #5C236A;
}

button[id="delete"]:hover .delete-icon {
  color: #b43d3d;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
}

img {
  width: 350px;
  /* Adjust the size as needed */
  height: auto;
  /* Adjust the size as needed */
  border-radius: 50%;
  margin: 0 auto;
  /* Center the circular image horizontally */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  /* Center the image vertically */
  align-items: center;
}

.heroImage {
  border-radius: 4px;
  border: 1px solid #5C236A;
  background: #040404;
  width: 100%;
  box-shadow: 0px 10px 60px 20px rgba(179, 0, 224, 0.50);
  margin-top: 120px;
  transition: box-shadow .3s;
}

button[id="logout"]:hover,
button[id="login"]:hover {
  box-shadow: 0px 10px 60px 20px rgba(179, 0, 224, 0.30);
  background: linear-gradient(217deg, #DB5BFB 0%, #DB5BFB 100%);
  transition: box-shadow .3s;
}

button[id="logout"]:active,
button[id="login"]:active {
  background-color: #2d5db0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button[type="submit"]:hover,
button[id="record"]:hover,
button[id="stop"]:hover {
  background-color: #3d7de0;
}

button[type="submit"]:active,
button[id="record"]:active,
button[id="stop"]:active {
  background-color: #2d5db0;
}

button[id="stop"]:hover {
  background-color: #e05757;
}

button[id="stop"]:active {
  background-color: #b43d3d;
}

/* Button styles */
button[type="submit"]:hover,
button[id="record"]:hover,
button[id="stop"]:hover {
  background-color: #3d7de0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button[type="submit"]:active,
button[id="record"]:active,
button[id="stop"]:active {
  background-color: #2d5db0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button[id="stop"]:hover {
  background-color: #e05757;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button[id="stop"]:active {
  background-color: #b43d3d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Spinning loader */
.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}



#text-form {
  display: none;
}

.audio-wave {
  border: 1px solid #2D2D2D;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  margin-right: 20px;
}

.recording-control-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.card {
  border-radius: 4px;
  border: 1px solid #5C236A;
  background: rgba(35, 35, 35, 0.10);
  box-shadow: 0px 10px 60px 20px rgba(179, 0, 224, 0.10);
  padding: 20px;
  margin: 20px 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
}

.name {
  color: white;

}

.card:hover {
  box-shadow: 0 17px 24px rgba(0, 0, 0, 0.2);
}


.project-card a {
  text-decoration: none;
}

.project-name {
  color: white;
  font-size: 24px;
  margin-bottom: 10px;
}

.project-tags {
  font-size: 17px;
  color: #DB5BFB;
}

.hero {
  padding-top: 64px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#hero-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h1,
h2 {
  position: relative;
  z-index: 1;
}

.landing-body {
  background: black;

}

.container-body {
  /* max-width: 1200px; */
  max-width: 90%;
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoLink {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  cursor: pointer;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  margin: 0px;
  border-bottom: 1px solid #5C236A;
}

.nav-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.nav-item {
  list-style: none;
  color: white;
  margin-right: 24px;
  cursor: pointer;
}

.nav-item:hover {
  color: #DB5BFB;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid rgba(92, 35, 106, .5);
  backdrop-filter: blur(20px);
  box-shadow: 0px 10px 60px 20px rgba(179, 0, 224, 0.10);

}

footer {
  width: 100%;
  border-top: 1px solid #5C236A;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  margin: 0px;
  gap: 12px;
}

.footer ul li {
  list-style-type: none;
}

.footer a {
  color: white;
}

.logo {
  width: 160px;
  border-radius: 0px;
  display: inline-block;
  margin: 0px;
}

button[id=navButton] {
  border-radius: 200px;
  background: linear-gradient(217deg, #B300E0 0%, #DB5BFB 100%);
  border: none;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  max-width: 400px;
  margin: 0px;
  box-shadow: 0px 10px 60px 20px rgba(179, 0, 224, 0.00);
  transition: background 0.5s ease-in-out;
}

button[id=navButton]:hover {
  transition: background 0.5s ease-in-out;
  background: linear-gradient(217deg, #DB5BFB 0%, #DB5BFB 100%);
}

.testimonials {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  ;
}

a {
  text-decoration: none;
}

.features {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  flex-wrap: nowrap;
}

.features .card {
  margin: 10px 0px;
}

.featurerow {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 20px
}

.featurerowrev {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px
}

.profound {
  margin-top: 80px;
  margin-bottom: 40px;
}

.featureImage {
  margin-top: 20px;
  margin-bottom: 20px;
}

section {
  margin-top: 60px;
  margin-bottom: 40px;
}

.project-card {
  background: linear-gradient(45deg, rgba(105, 0, 131, 0.3), rgba(105, 0, 131, 0.2));
  box-shadow: rgba(179, 0, 224, 0.10);
  border-radius: 4px;
  border: 1px solid #5C236A;
  padding: 20px;
  margin: 20px 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
}

.project-card:hover {
  box-shadow: rgba(179, 0, 224, 0.50);
  transition: box-shadow 0.3s ease-in-out;
}

#root .inputSectionHeader {
  text-align: left;
}

.input-card {
  background: linear-gradient(45deg, rgba(105, 0, 131, 0.3), rgba(105, 0, 131, 0.2));
  box-shadow: rgba(179, 0, 224, 0.10);
  border-radius: 4px;
  border: 1px solid #5C236A;
  padding: 8px;
  margin: 20px 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
}

.input-card:hover {
  box-shadow: rgba(179, 0, 224, 0.50);
  transition: box-shadow 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 60px;
  }

  .container-body {
    max-width: 100%;
    margin: 0px;

  }

  .featurerow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .featurerowrev {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .featureImage {
    max-width: 100%;
    margin: 5px;
  }

  .project-card {
    background: linear-gradient(45deg, rgba(105, 0, 131, 0.3), rgba(105, 0, 131, 0.2));
    box-shadow: rgba(179, 0, 224, 0.10);
    border-radius: 4px;
    border: 1px solid #5C236A;
    padding: 20px;
    margin: 20px 0px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
  }

  .project-card:hover {
    box-shadow: rgba(179, 0, 224, 0.50);
    transition: box-shadow 0.3s ease-in-out;
  }

  .input-card {
    background: linear-gradient(45deg, rgba(105, 0, 131, 0.3), rgba(105, 0, 131, 0.2));
    box-shadow: rgba(179, 0, 224, 0.10);
    border-radius: 4px;
    border: 1px solid #5C236A;
    padding: 8px;
    margin: 20px 0px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    position: relative;
  }

  .input-card:hover {
    box-shadow: rgba(179, 0, 224, 0.50);
    transition: box-shadow 0.3s ease-in-out;
  }

  .features {
    width: 100%;
    flex-wrap: nowrap;

  }

  section {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .testimonials {
    flex-direction: column;
  }

  .card.feature-small {
    width: 100%;
  }

  .card.feature-large {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 32px;
    line-height: 48px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  .heroImage {
    margin-top: 60px;
  }

  .container-body {
    max-width: 100%;
    padding: 20px;
    margin: 0px;
  }

  .testimonials {
    flex-direction: column;
  }


}